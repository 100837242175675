import VueChartJS, { mixins } from "vue-chartjs";
// import Chart from "chart.js";
// import ChartPluginCrossHair from "./chartjs-plugin-crosshair.js";

// Chart.plugins.unregister(ChartPluginCrossHair);

export default {
  extends: VueChartJS.Bar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    this.renderChart(this.chartData, this.options);

    // const titleblock = this.$data._chart.titleBlock;

    // titleblock.right = 100;
    // titleblock.left = 0;
    // titleblock.margins.left = 0;
    // console.log(titleblock);
  },
  watch: {
    chartData: function() {
      this.$data._chart.options = this.options;
      this.$data._chart.update();

  
    },
    "chartData.datasets": function() {
      this.$data._chart.options = this.options;
      this.$data._chart.data = this.chartData;
      this.$data._chart.update();

    },
  },
};
