const WeekName = ["", "일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

import ItemColor from "./CardColor.json";
import Store from "@src/config/PageStore.vue";

export default {
	selectChartIdx: 0,

	getItemColor(section, divCd, type) {
		let opacity = "";
		let color = ItemColor["Common"]["Default"] + opacity;

		try {
			if (divCd === "Total" || divCd === "Tot") color = ItemColor["Common"]["Total"] + opacity;
			else if (type) color = ItemColor[section][type][divCd] + opacity;
			else color = ItemColor[section][divCd] + opacity;
		} catch (e) {
			console.error("getCardColor() error: ", e);
			console.warn(`Item Color has not found. Set it to Default Color: section:${section}, divCd:${divCd}`);
		}

		return color;
	},
	getCardColor(section, divCd, type) {
		let opacity = "dd";
		let color = ItemColor["Common"]["Default"] + opacity;

		try {
			if (divCd === "Total" || divCd === "Tot" || divCd == "TotCost") color = ItemColor["Common"]["Total"] + opacity;
			else if (type) color = ItemColor[section][type][divCd] + opacity;
			else color = ItemColor[section][divCd] + opacity;
		} catch (e) {
			console.error("getCardColor() error: ", e);
			console.warn(`Item Color has not found. Set it to Default Color: section:${section}, divCd:${divCd}`);
		}

		return color;
	},

	getChartBaseColor(section, divCd, type) {
		let opacity = "aa";
		let color = ItemColor["Common"]["Default"] + opacity;

		try {
			if (divCd === "Total" || divCd === "Tot") color = ItemColor["Common"]["Total"] + opacity;
			else if (type) color = ItemColor[section][type][divCd] + opacity;
			else color = ItemColor[section][divCd] + opacity;
		} catch (e) {
			console.error("getChartBaseColor() error: ", e);
			console.warn(`Item Color has not found. Set it to Default Color: section:${section}, divCd:${divCd}`);
		}

		return color;
	},

	getChartCompareColor(section, divCd, type) {
		let opacity = "33";
		let color = ItemColor["Common"]["Default"] + opacity;

		try {
			if (divCd === "Total" || divCd === "Tot") color = ItemColor["Common"]["Total"] + opacity;
			else if (type) color = ItemColor[section][type][divCd] + opacity;
			else color = ItemColor[section][divCd] + opacity;
		} catch (e) {
			console.error("getChartCompareColor() error: ", e);
			console.warn(`Item Color has not found. Set it to Default Color: section:${section}, divCd:${divCd}`);
		}

		return color;
	},

	// getMixedChartOptions(options) {
	getCompareChartOptions(options, baseLabel, compLabel) {
		console.log(baseLabel, compLabel);
		return {
			responsive: true,
			maintainAspectRatio: false,
			animation: { duration: 1000, easing: "easeOutQuart" },
			hoverMode: "index",
			layout: {
				padding: { left: 0, right: 0, top: 0, bottom: 0 },
			},
			legend: {
				display: true,
				align: "end",
				labels: { usePointStyle: true },
			},
			event: ["click"],
			onClick: (a, b) => {
				let index = null;
				if (b !== undefined && b.length > 0) index = b[0]._index;

				Store.commit("SET_CHART_SELECT_VALUE", index);
			},
			tooltips: {
				enabled: true,
				mode: "x",
				position: "nearest",
				callbacks: {
					custom: function(tooltipModel) {
						tooltipModel.x = 10;
					},
					label: function(tooltipItem, data) {
						var label = data.datasets[tooltipItem.datasetIndex].label;
						var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
						if (label) {
							label += ": ";
						}

						if (!value) {
							label += "0";
						} else {
							label += value;
						}

						return label;
					},
					labelColor: function(tooltipItem, data) {
						let color = {
							borderColor: data.config.data.datasets[tooltipItem.datasetIndex].borderColor,
							backgroundColor: data.config.data.datasets[tooltipItem.datasetIndex].backgroundColor,
						};

						return color;
					},
					title: function(tooltipItem) {
						let title = "";

						if (tooltipItem[0].datasetIndex === 0) {
							title = baseLabel[tooltipItem[0].index];
						} else {
							title = compLabel[tooltipItem[0].index];
						}

						return title;
					},
				},
			},
			hover: { intersect: false },
			scales: {
				xAxes: [
					{
						id: "x-axis-0",
						stacked: true,
						ticks: {
							autoSkip: true,
							fontColor: "#000000",
							fontSize: 12,
							callback: (val, index) => {
								return index % options.labelSkip === 0 ? val : "";
							},
						},
					},
				],
				yAxes: [
					{
						id: "Left_yAxis",
						display: true,
						position: "left",
						stacked: true,
						ticks: { beginAtZero: true, fontColor: "#000000", fontSize: 12 },
					},
					{
						id: "Right_yAxis",
						display: true,
						position: "right",
						stacked: false,
						ticks: { beginAtZero: true, fontColor: "#000000", fontSize: 12 },
					},
				],
			},
		};
	},

	// getPieChartOptions() {
	getSummaryRatioChartOptions() {
		return {
			responsive: true,
			maintainAspectRatio: false,
			aspectRatio: 1.2,

			animation: { duration: 1000, easing: "easeOutQuart" },
			cutoutPercentage: 60,
			radiusBackground: { color: "#d1d1d1" },
			legend: {
				display: false,
				position: "bottom",
				align: "center",
				labels: { usePointStyle: true, boxWidth: 10, fontSize: 12, fontColor: "#666" },
			},
			layout: {
				padding: { left: 37, right: 37, top: 37, bottom: 37 },
			},
			tooltips: { mode: "index", intersect: true, callbacks: {} },
			plugins: {
				datalabels: {
					display: "auto",
					color: "#666",
					anchor: "end",
					font: { size: 12 },
					align: "end",
					padding: 5,
					clamp: true,
					visibility: "auto",
					labels: {
						title: { font: { weight: "bold" } },
						value: { color: "green" },
					},
					formatter: function(value, context) {
						return [context.chart.data.labels[context.dataIndex], `(${value} %)`];
					},
				},
			},
		};
	},

	makeAnalysisData(searchResult) {
		// Yearly의 경우 baseData와 compData가 없음.
		// result.resultData :
		// header[4]
		// detail[5]
		if (!searchResult.data.baseData || searchResult.data.detail) {
			// TODO: 향후 제거해야 할 코드
			console.warn("{TODO}: Yearly 조회 API 규격 변경 필요 (result.detail -> result.baseData)");
			searchResult.data.baseData = searchResult.data.detail;
		}

		if (!searchResult.data.baseData && !searchResult.data.header) {
			console.warn("searchResult is Empty.");
			return null;
		}

		// remove summary property & generate new Summary
		let newSummary = [];

		searchResult.data.summary = [];
		searchResult.data.header.forEach((data) => {
			if (data.divCd !== "Tot" && data.divCd !== "Total") {
				let sumItem = { divCd: "", divCdName: "", unit: "kWh", baseVal: 0, compVal: 0, diffVal: 0 };

				// Create New Summary Item.
				sumItem.divCd = data.divCd;
				sumItem.divCdName = data.divCdName;
				// TODO: Data Unit
				if (data.hasOwnProperty("unit")) sumItem.unit = data.unit;

				// Api 호출결과에 '-' 값이 null값을 대신하여 반환되어 산술에러(NaN)가 발생하니 예외처리 필요.
				// baseData와 compData의 값으로 baseVal, compVal 합산을 계산함.
				sumItem.baseVal = searchResult.data.baseData && searchResult.data.baseData.reduce((acc, item) => acc + (item[data.divCd] === "-" ? 0 : Number(item[data.divCd])), 0);
				sumItem.compVal = searchResult.data.compData && searchResult.data.compData.reduce((acc, item) => acc + (item[data.divCd] === "-" ? 0 : Number(item[data.divCd])), 0);
				sumItem.diffVal = sumItem.baseVal - sumItem.compVal;
				sumItem.diffRate = ((sumItem.baseVal - sumItem.compVal) / sumItem.baseVal) * 100;

				// 실수형 데이터의 정밀도를 소숫점 1자리로 제한함.
				sumItem.baseVal = sumItem.baseVal && Number(sumItem.baseVal.toFixed(1));
				sumItem.compVal = sumItem.compVal && Number(sumItem.compVal.toFixed(1));
				sumItem.diffVal = sumItem.diffVal && Number(sumItem.diffVal.toFixed(1));
				sumItem.diffRate = sumItem.diffRate && Number(sumItem.diffRate.toFixed(1));

				// Make newSummary Array.
				newSummary.push(sumItem);
			}
		});

		// Make Item (Total) of Summary Array
		let sumItem = { divCd: "Total", divCdName: "전체", unit: "kWh", baseVal: 0, compVal: 0, diffVal: 0 };

		// Calculate baseVal, compVal, diffVal
		sumItem.baseVal = newSummary.reduce((acc, item) => acc + item.baseVal, 0);
		sumItem.compVal = newSummary.reduce((acc, item) => acc + item.compVal, 0);
		sumItem.diffVal = sumItem.baseVal - sumItem.compVal;
		sumItem.diffRate = ((sumItem.baseVal - sumItem.compVal) / sumItem.baseVal) * 100;

		// Decimal Normalization : Number Type, Precision: 1 Digit
		sumItem.baseVal = sumItem.baseVal && Number(sumItem.baseVal.toFixed(1));
		sumItem.compVal = sumItem.compVal && Number(sumItem.compVal.toFixed(1));
		sumItem.diffVal = sumItem.diffVal && Number(sumItem.diffVal.toFixed(1));
		sumItem.diffRate = sumItem.diffRate && Number(sumItem.diffRate.toFixed(1));

		// Sorting newSummary with baseVal
		newSummary.sort((a, b) => b.baseVal - a.baseVal);

		// Replace Result's Summary Object with newSummary
		searchResult.data.summary = [];
		searchResult.data.summary.push(sumItem);
		searchResult.data.summary.push(...newSummary);

		return searchResult;
	},

	makeUseTargetAnalysisData(searchResult) {
		if (!searchResult.data.baseData || !searchResult.data.header) {
			console.warn("searchResult is Empty.");
			return null;
		}

		// UseTarget은 누적값이기 때문에 Server에서 Summary를 만들어 보내줌.
		searchResult.data.summary.forEach((sumItem) => {
			// 목표관리에서는 소숫점 자리는 의미없으므로 절삭함.
			// sumItem.ptVal 이 string으로 전달되는 경우도 있음. (ex: 연면적)
			sumItem.ptVal = Number(Number(sumItem.ptVal).toFixed(0));
			// 단, Summary에 unit이 없다면 header에서 찾아 설정함.
			if (!sumItem.unit) {
				sumItem.unit = searchResult.data.header.find((item) => item.divCd === sumItem.divCd)["unit"];
			}
		});

		return searchResult;
	},

	makeEnergyPredictionAnalysisData(searchResult) {
		if (!searchResult.data.baseData || !searchResult.data.header) {
			console.warn("searchResult is Empty.");
			return null;
		}

		// searchResult.data.summary.forEach( sumItem => {
		// sumItem.ptVal 이 string으로 전달되는 경우도 있음. (ex: 연면적)
		// sumItem.ptVal = Number(Number(sumItem.ptVal).toFixed(0));
		// sumItem.unit = searchResult.data.header.find(item => item.divCd === sumItem.divCd)['unit'];
		// });

		return searchResult;
	},

	makeIndoorEnvironmentData(searchResult) {
		let newHeader = [];

		for (let i = 0; i < searchResult.data.header.length; i++) {
			newHeader.push({
				divCd: searchResult.data.header[i].equipPropCode,
				divCdName: searchResult.data.header[i].equipPropName,
				unit: searchResult.data.header[i].unitSmallCode,
			});
		}

		searchResult.data.header = newHeader;

		return searchResult;
	},

	makeOrgUnitAnalysisData(searchResult) {
		if (!searchResult.data.baseData || !searchResult.data.header) {
			console.warn("searchResult is Empty.");
			return null;
		}

		// 건물 연면적은 차트 표시에서 제외함
		searchResult.data.header.forEach((headerItem) => {
			if (headerItem.divCd === "totArea" || headerItem.divCd === "unitValue") headerItem.hidden = true;
			else headerItem.hidden = false;
		});

		searchResult.data.baseData.forEach((items) => {
			if (!items.regDtTag) {
				items.regDtTag = items.regDt;
			}
		});

		if (!searchResult.data.summary) {
			searchResult.data.summary = [];

			searchResult.data.header.forEach((data) => {
				if (data.divCd !== "Tot" && data.divCd !== "Total") {
					let sumItem = { divCd: "", divCdName: "", unit: "kWh", baseVal: 0, compVal: 0, diffVal: 0 };

					// Create New Summary Item.
					sumItem.divCd = data.divCd;
					sumItem.divCdName = data.divCdName;
					// TODO: Data Unit
					if (data.hasOwnProperty("unit")) sumItem.unit = data.unit;

					// Api 호출결과에 '-' 값이 null값을 대신하여 반환되어 산술에러(NaN)가 발생하니 예외처리 필요.
					// baseData와 compData의 값으로 baseVal, compVal 합산을 계산함.
					sumItem.baseVal = searchResult.data.baseData && searchResult.data.baseData.reduce((acc, item) => acc + (item[data.divCd] === "-" ? 0 : Number(item[data.divCd])), 0);
					sumItem.compVal = searchResult.data.compData && searchResult.data.compData.reduce((acc, item) => acc + (item[data.divCd] === "-" ? 0 : Number(item[data.divCd])), 0);
					sumItem.diffVal = sumItem.baseVal - sumItem.compVal;
					sumItem.diffRate = ((sumItem.baseVal - sumItem.compVal) / sumItem.baseVal) * 100;

					// 실수형 데이터의 정밀도를 소숫점 1자리로 제한함.
					sumItem.baseVal = sumItem.baseVal && Number(sumItem.baseVal.toFixed(1));
					sumItem.compVal = sumItem.compVal && Number(sumItem.compVal.toFixed(1));
					sumItem.diffVal = sumItem.diffVal && Number(sumItem.diffVal.toFixed(1));
					sumItem.diffRate = sumItem.diffRate && Number(sumItem.diffRate.toFixed(1));

					// Make newSummary Array.
					searchResult.data.summary.push(sumItem);
				}
			});
		}

		// UseTarget은 누적값이기 때문에 Server에서 Summary를 만들어 보내줌.
		// searchResult.data.summary.forEach((sumItem) => {
		// 목표관리에서는 소숫점 자리는 의미없으므로 절삭함.
		// sumItem.ptVal 이 string으로 전달되는 경우도 있음. (ex: 연면적)
		// sumItem.ptVal = Number(Number(sumItem.ptVal).toFixed(0));
		// 단, unit은 보내주지 않기 때문에 Header에서 참조하여 설정함.
		// sumItem.unit = searchResult.data.header.find((item) => item.divCd === sumItem.divCd)["unit"];
		// });

		return searchResult;
	},

	// makeUsageCostMultiItemData(resultData) {
	// 	let returnData = {
	//     baseData: [],
	//     chartData: [],
	//     header: [],
	//     summary: []
	//   };

	//   // let maxTotUsage = resultData.

	//   for(let i = 0; i < resultData.length; i++){
	//     let data = resultData[i];

	//     let newSummary = {
	//       baseVal: data.summary.find(v => v.divCd === "TotUsage").baseVal,
	//       compVal: data.summary.find(v => v.divCd === "TotUsage").compVal,
	//       diffVal: data.summary.find(v => v.divCd === "TotUsage").diffVal,
	//       unit: data.summary.find(v => v.divCd === "TotUsage").unit,
	//       divCd: `${data.costInfo.ptAddr}_TotUsage`,
	//       divCdName: `${data.costInfo.ptName} 사용량`
	//     }
	//   }
	// },

	/** 작성중 by kim */
	makeUsageCostData(searchResult) {
		// let timeDsvn = "";

		let resultData = searchResult;
		console.log(resultData);
		if (!searchResult.data.baseData || searchResult.data.detail) {
			// TODO: 향후 제거해야 할 코드
			console.warn("{TODO}: Yearly 조회 API 규격 변경 필요 (result.detail -> result.baseData)");
			searchResult.data.baseData = searchResult.data.detail;
			searchResult.data.baseData = searchResult.data.baseData.map((data) => {
				data.regDtTag = data.regDt;

				return data;
			});
			searchResult.data.chartData = searchResult.data.detail;

			if (searchResult.searchType !== "allEnergy") {
				searchResult.data.header = [searchResult.data.header];
			}
		}

		if (!searchResult.data.baseData || !searchResult.data.header) {
			console.warn("searchResult is Empty.");
			return null;
		}

		let newSummary = [];
		let newChart = [];
		let newDataGrid = [];
		let compDataGrid = [];
		// let maxRateValue = 0;

		searchResult.data.summary = [];

		for (const data of searchResult.data.header) {
			// if(data.divCd === 'TotUsage') continue;

			let sumItem = { divCd: "", divCdName: "", unit: "", baseVal: 0, compVal: 0, diffVal: 0, circleRate: null };

			sumItem.divCd = data.divCd;
			sumItem.divCdName = data.divCdName;
			sumItem.unit = data.unit;
			sumItem.dispOptions = data.dispOptions;

			// if (data.divCd === "BasePeak") sumItem.dispOptions.chart = false;

			// if (data.divCd === "TotCost") sumItem.dispOptions.summary = false;

			// if (data.divCd === "TotUsage") sumItem.dispOptions.summary = false;

			// if (data.divCd === "basicFee") sumItem.dispOptions.dataGrid = false;

			// sumItem.dispOptions = {
			// 	summary: true,
			// 	chart: true,
			// 	dataGrid: true,
			// };
			// console.log(sumItem.dispOptions.summary);

			// sumItem.baseVal = searchResult.data.baseData && searchResult.data.baseData.reduce((acc, item) => acc + (item[`${data.divCd}`] === "-" ? 0 : Number(item[`${data.divCd}`])), 0);
			sumItem.baseVal = searchResult.data.baseData.reduce((acc, item) => acc + (item[`${data.divCd}`] === "-" ? 0 : Number(item[`${data.divCd}`])), 0);
			sumItem.compVal = searchResult.data.compData && searchResult.data.compData.reduce((acc, item) => acc + (item[data.divCd] === "-" ? 0 : Number(item[data.divCd])), 0);
			sumItem.diffVal = sumItem.baseVal - sumItem.compVal;
			sumItem.diffRate = ((sumItem.baseVal - sumItem.compVal) / sumItem.baseVal) * 100;

			sumItem.baseVal = sumItem.baseVal && Number(sumItem.baseVal.toFixed(1));
			sumItem.compVal = sumItem.compVal && Number(sumItem.compVal.toFixed(1));
			sumItem.diffVal = sumItem.diffVal && Number(sumItem.diffVal.toFixed(1));
			sumItem.diffRate = sumItem.diffRate && Number(sumItem.diffRate.toFixed(1));

			newSummary.push(sumItem);

			let baseChartData = searchResult.data.baseData.map((baseVal) => {
				return { regDt: baseVal.regDt, regDtTag: baseVal.regDtTag, [data.divCd]: baseVal[data.divCd] !== "-" && !global.xe.isEmpty(baseVal[data.divCd]) ? baseVal[data.divCd].toFixed(1) : "-" };
			});

			if (newChart.length !== 0) {
				newChart = newChart.map((newChart) => {
					let chartVal = baseChartData.find((t) => t.regDt === newChart.regDt);
					return {
						...newChart,
						[data.divCd]: chartVal[data.divCd],
					};
				});
			} else {
				newChart = baseChartData;
			}

			// let chartData = searchResult.data.baseData.map((baseVal) => {
			// 	return { regDt: baseVal.regDt, regDtTag: baseVal.regDtTag, [data.divCd]: baseVal[data.divCd] !== "-" && !global.xe.isEmpty(baseVal[data.divCd]) ? baseVal[data.divCd].toFixed(1) : "-" };
			// });

			// console.log(chartData);

			if (newDataGrid.length !== 0) {
				newDataGrid = newDataGrid.map((newDataGrid) => {
					let chartVal = baseChartData.find((t) => t.regDt === newDataGrid.regDt);
					return {
						...newDataGrid,
						[data.divCd]: chartVal[data.divCd],
					};
				});
			} else {
				newDataGrid = baseChartData;
			}

			if (searchResult.data.compData) {
				let chartData = searchResult.data.compData.map((compVal) => {
					return { regDt: compVal.regDt, regDtTag: compVal.regDtTag, [data.divCd]: compVal[data.divCd] !== "-" && !global.xe.isEmpty(compVal[data.divCd]) ? compVal[data.divCd].toFixed(1) : "-" };
				});

				if (compDataGrid.length !== 0) {
					compDataGrid = compDataGrid.map((compDataGrid) => {
						let chartVal = chartData.find((t) => t.regDt === compDataGrid.regDt);
						return {
							...compDataGrid,
							[data.divCd]: chartVal[data.divCd],
						};
					});
				} else {
					compDataGrid = chartData;
				}
			}

			// chartData = sumItem.chartVal && Number(sumItem.chartVal.toFixed(1));
		}
		resultData.data.baseData = searchResult.data.summary = [];
		searchResult.data.summary.push(...newSummary);
		searchResult.data.chartData = newChart;
		searchResult.data.gridData = newDataGrid;
		searchResult.data.compGridData = compDataGrid;

		// console.log(searchResult);

		return searchResult;
	},

	formatDatetime(date, type = null) {
		// date format string
		//  - yyyymmddhhmi (12)
		//  - yyyymmdd (8)
		//  - yyyymm (6)
		//  - 2019 년 (6)
		//  - 1 ~ 7 (1)
		let result = "";
		let dateLen = date.length;

		if (type !== null) {
			result = date;
			return result;
		}

		try {
			if (dateLen >= "yyyymmddhhmi".length) {
				result = date.substr(4, 2) + "/" + date.substr(6, 2) + " " + date.substr(8, 2) + ":" + date.substr(10, 2);
			} else if (dateLen >= "yyyymmddhh".length) {
				result = date.substr(4, 2) + "/" + date.substr(6, 2) + " " + date.substr(8, 2) + ":00";
			} else if (dateLen >= "yyyymmdd".length) {
				result = date.substr(0, 4) + "/" + date.substr(4, 2) + "/" + date.substr(6, 2);
			} else if (dateLen >= "yyyymm".length) {
				result = date.substr(0, 4) + "/" + date.substr(4, 2);
			} else if (dateLen >= "yyyy".length) {
				result = date;
			} else {
				result = WeekName[Number(date)];
			}
		} catch (e) {
			console.error("error: ", e);
		}

		return result;
	},
	formatDatetimeForEquipPerform(date, type = null) {
		let result = "";
		let dateLen = date.length;

		if (type !== null) {
			result = date;
			return result;
		}
    // console.log(dateLen, "2023년 11월".length);
    // console.log(date.substr(6, 2));
		try {
			if (dateLen == "2023년 11월".length) {
				result = date.substr(0, 4) + "/" + date.substr(6, 2);
			} else if (dateLen == "11월 27일".length) {
				result = date.substr(0, 2) + "/" + date.substr(4, 2);
			} else {
				result = date;
			}
		} catch (e) {
			console.error("error: ", e);
		}

		return result;
	},
	formatDatetimeForOrgUnit(date, type = null) {
		let result = "";
		let dateLen = date.length;

		if (type !== null) {
			result = date;
			return result;
		}
    console.log(date);
    console.log("2023년 07월".length, "2019 년".length, dateLen)
		try {
			if (dateLen == "2023년 07월".length) {
				result = date.substr(0, 4) + "/" + date.substr(6, 2);
			} else if (dateLen == "2019 년".length) {
				result = date.substr(0, 4)
			} else {
				result = date.substr(0, 2) + "/" + date.substr(4, 2);
			}
		} catch (e) {
			console.error("error: ", e);
		}

		return result;
	},

	localeDateTime(date, lang, dateType = "day") {
		// console.log(date, lang, dateType);
		let dateSplit = date.toString().split(" ");
		let result = "";
		let year = "";
		let month = "";
		let day = "";
		let hour = "";
		let minute = "";

		if (lang === "en") {
			if (dateType.toLowerCase() === "month") {
				for (let i = 0; i < dateSplit.length; i++) {
					if (dateSplit[i].includes("년")) {
						year = dateSplit[i].split("년")[0];
					} else if (dateSplit[i].includes("월")) {
						month = global.xe.$t(Number(dateSplit[i].split("월")[0]) + "월");
					} else if (dateSplit[i].includes("일")) {
						day = global.xe.$t(Number(dateSplit[i].split("일")[0]) + "일");
					}
				}
				result = `${month} ${day}, ${year}`;
			} else if (dateType.toLowerCase() === "week") {
				result = global.xe.$t(date);
			} else if (dateType.toLowerCase() === "year") {
				for (let i = 0; i < dateSplit.length; i++) {
					if (dateSplit[i].includes("년")) {
						year = dateSplit[i].split("년")[0];
					} else if (dateSplit[i].includes("월")) {
						month = global.xe.$t(Number(dateSplit[i].split("월")[0]) + "월");
					}
				}
				result = `${month} ${year}`;
			} else if (dateType.toLowerCase() === "yearly") {
				result = date.split("년")[0];
			} else if (dateType.toLowerCase() === "day") {
				for (let i = 0; i < dateSplit.length; i++) {
					if (dateSplit[i].includes("년")) {
						year = dateSplit[i].split("년")[0];
					} else if (dateSplit[i].includes("월")) {
						month = global.xe.$t(Number(dateSplit[i].split("월")[0]) + "월");
					} else if (dateSplit[i].includes("일")) {
						day = global.xe.$t(Number(dateSplit[i].split("일")[0]) + "일");
					} else if (dateSplit[i].includes("시")) {
						hour = dateSplit[i].split("시")[0] + global.xe.$t("시");
					}
				}
				result = `${month} ${day} ${year}, ${hour}`;

				// result = date;
			} else if (dateType.toLowerCase() === "hour") {
				for (let i = 0; i < dateSplit.length; i++) {
					if (dateSplit[i].includes("년")) {
						year = dateSplit[i].split("년")[0];
					} else if (dateSplit[i].includes("월")) {
						month = global.xe.$t(Number(dateSplit[i].split("월")[0]) + "월");
					} else if (dateSplit[i].includes("일")) {
						day = global.xe.$t(Number(dateSplit[i].split("일")[0]) + "일");
					} else if (dateSplit[i].includes(":")) {
						hour = dateSplit[i].split(":")[0];
						minute = dateSplit[i].split(":")[1].split("분")[0];
					}
				}

				result = `${month} ${day} ${year}, ${hour}:${minute}`;
			}
		} else {
			result = date.toString();
		}

		return result;
	},

	// makeNullCompDataSet(defaultSet, compParam) {
	// if(compParam.timeDsvn
	//   )

	// console.log(defaultSet, compParam);
	// let fromDate = moment(compParam.fromDate);
	// let toDate = moment(compParam.toDate);
	// console.log(newDate);

	// },

	// Component Isolation 위반 by badblock
	// SearchBox Component에서 상위 컨테이너의 표시 문구에 관여하고 있음.
	// getLabel() 함수는 공통화 해야함.
	getSearchOptionLabel(options) {
		let result = null;

		try {
			if (options.conditions.timeDsvn == "Hour") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년 MM월 dd일"),
					compareLabel: options.datePicker.compFromDate.format("yyyy년 MM월 dd일"),
					chartTitle: "15분",
				};
			} else if (options.conditions.timeDsvn == "Day") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년 MM월 dd일"),
					compareLabel: options.datePicker.compFromDate.format("yyyy년 MM월 dd일"),
					chartTitle: "시간별",
				};
			} else if (options.conditions.timeDsvn == "Week") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년") + " " + new Date(options.datePicker.fromDate).getWeek() + "주차",
					compareLabel: options.datePicker.compFromDate.format("yyyy년") + " " + new Date(options.datePicker.compFromDate).getWeek() + "주차",
					searchCondition: "요일별",
					chartTitle: "요일별",
				};
			} else if (options.conditions.timeDsvn == "Month") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년 MM월"),
					compareLabel: options.datePicker.compFromDate.format("yyyy년 MM월"),
					chartTitle: "일별",
				};
			} else if (options.conditions.timeDsvn == "Year") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년"),
					compareLabel: options.datePicker.compFromDate.format("yyyy년"),
					chartTitle: "월별",
				};
			} else if (options.conditions.timeDsvn == "Yearly") {
				result = {
					regDtLabel: "조회기준",
					baseLabel: options.datePicker.fromDate.format("yyyy년"),
					compareLabel: options.datePicker.compFromDate.format("yyyy년"),
					chartTitle: "연도별",
				};
			}
		} catch (e) {
			console.error("ZoneEnergySourceSearch Error: ", e);
		}

		return result;
	},

	getDownloadFileName(prefix, type, options) {
		let fileName = "";

		let optionLabel = this.getSearchOptionLabel(options);
		if (optionLabel) {
			if (type === "base") {
				fileName = prefix + `(${optionLabel.chartTitle})-` + optionLabel.baseLabel.replace(/ /g, "") + ".xls";
			} else if (type === "comp") {
				fileName = prefix + `(${optionLabel.chartTitle})-` + optionLabel.compareLabel.replace(/ /g, "") + ".xls";
			}
		}

		return fileName;
	},
};
